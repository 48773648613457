.event-status-page {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: "Secular One", sans-serif;
  text-align: center;
  padding: 10px;
}

.event-status-header,
.event-status-subheader,
.days-remaining {
  margin-bottom: 10px;
}

.event-status-header {
  top: 0;
  font-size: 60px;
  font-weight: bold;
  color: var(--text-color);
  background: var(--button-background-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.event-status-header2 {
  font-size: 42px;
}

.event-status-subheader {
  font-size: 24px;
  color: var(--secondary-color);
}

.days-remaining {
  font-size: 36px;
  font-weight: bold;
  color: var(--primary-color);
}

.countdown-timer {
  margin-top: 10px;
  margin-bottom: 50px;
}

.error-message {
  color: var(--error-color);
  font-size: 16px;
  margin-top: 20px;
}

button.primary-button {
  background: var(--button-background-gradient);
  color: var(--button-text-color);
}

button.primary-button:hover {
  background: var(--button-light-background-gradient);
}
