.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 70%;
  max-width: 700px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: left;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.delete-btn {
  background: rgba(165, 3, 3, 0.825);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.archive-btn {
  background: rgba(1, 1, 212, 0.825);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  margin-left: 10px;
}

.popup-title {
  font-size: 24px;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 20px;
  text-align: center;
}

.status-ready {
  color: rgb(2, 158, 2);
}

.status-not-ready {
  color: rgb(202, 10, 10);
}

.popup-content p {
  direction: ltr;
}

.issues-list {
  list-style: disc;
  padding-left: 20px;
  margin: 0;
}

.issues-title {
  font-weight: bold;
  margin: 10px 0 5px;
}

.status-upcoming {
  color: rgb(91, 166, 206);
}

.status-ongoing {
  color: rgb(34, 152, 103);
}

.status-completed {
  color: rgb(196, 185, 33);
}

a {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.button-group {
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.edit-icon {
  cursor: pointer;
  margin-left: 10px;
  color: #000;
  display: inline;
}

.input-editing {
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
  display: inline;
  margin-right: 5px;
}

.popup-content select.input-editing {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  font-size: 14px;
  cursor: pointer;
}

.save-icon {
  cursor: pointer;
  margin-left: 10px;
  color: green;
  display: inline;
}

.date-picker-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.date-picker-container .input-editing {
  flex: 1;
}

@media only screen and (max-width: 600px) {
  .popup-content {
    width: 90%;
    max-width: none;
  }

  .date-picker-container {
    display: block;
  }

  .input-editing {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
}
