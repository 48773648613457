.event-not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  font-family: Arial, sans-serif;
  color: #333;
}

.event-not-found h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.event-not-found p {
  font-size: 1.5em;
}
