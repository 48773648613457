.result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: var(--background-color);
  font-family: "Secular One", sans-serif;
  padding: 0 1px;
  text-align: center;
  position: relative;
}

.result-title {
  width: 100%;
  font-size: 60px;
  font-weight: bold;
  color: var(--text-color);
  background: var(--button-background-gradient);
  -webkit-background-clip: text;
  background-clip: text; /* Standard property */
  -webkit-text-fill-color: transparent;
}

.result-image {
  height: auto;
  margin-bottom: 40px;
}

.button-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 400px;
}

.result-button-container {
  position: fixed;
  bottom: 10px;
}

.footer-button {
  background: var(--button-light-background-gradient);
  color: var(--button-text-color);
  font-weight: bold;
  padding: 20px;
  margin: 6px;
  border-radius: 15px;
  font-size: 36px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 5px 6px 7px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .footer-button:hover {
  transform: translateY(-2px);
  background: var(--button-hover-background-gradient);
} */

.footer-button:disabled {
  background: var(--button-disabled-background);
  color: var(--button-disabled-text-color);
  cursor: not-allowed;
  box-shadow: none;
}

.footer-button svg {
  margin-right: 10px;
}

.gif-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.status-gif {
  max-width: 80%;
  max-height: 80%;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}
