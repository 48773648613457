/* Result.css for MiniMove */

.simple-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--background-color);
  text-align: center;
  padding: 20px;
}

.title {
  font-size: 36px;
  margin-bottom: 20px;
  color: var(--text-color);
}

.next-button {
  background: var(--button-background-gradient);
  color: var(--button-text-color);
  font-weight: bold;
  font-size: 24px;
  padding: 15px 30px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 3px 4px 5px rgba(0, 0, 0, 0.15);
}

.next-button:hover {
  transform: translateY(-2px);
}

.next-button:active {
  transform: translateY(2px);
}
