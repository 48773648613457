.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: var(--background-color);
  font-family: "Secular One", sans-serif;
  text-align: center;
  padding: 10px;
  position: relative;
}

.home-title h1 {
  margin: 10px 0;
}

.header-image {
  max-width: 100%;
  height: auto;
  width: auto;
}

.gif-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 90px;
}

.gif-container img {
  width: 80%;
  height: auto;
  opacity: 0.88;
}

.start-button-container {
  position: fixed;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.start-button {
  background: var(--button-light-background-gradient);
  color: var(--button-text-color);
  font-weight: bold;
  padding: 20px;
  margin: 6px;
  border-radius: 15px;
  font-size: 36px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 5px 6px 7px rgba(0, 0, 0, 0.25);
}

.start-button:hover {
  transform: translateY(-2px);
}
