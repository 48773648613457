.face-gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: var(--background-color);
  font-family: "Secular One", sans-serif;
  padding: 0 20px;
  text-align: center;
  position: relative;
}

.face-gallery-title {
  width: 100%;
  font-size: 56px;
  font-weight: bold;
  color: var(--text-color);
  background: var(--button-background-gradient);
  -webkit-background-clip: text;
  background-clip: text; /* Standard property */
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

.face-gallery-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 100%;
  max-width: 1000px;
  margin-bottom: 125px; /* Space before the footer */
}

.face-gallery-image-container {
  position: relative;
}

.face-gallery-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  cursor: pointer;
}

.face-gallery-image-selected {
  border: 0px solid var(--primary-color);
}

.face-gallery-image-unselectable {
  opacity: 0.5;
}

.face-gallery-image-overlay {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(41, 84, 163, 0.327);
  pointer-events: none;
}

.face-gallery-button-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 10px 0;
  background-color: var(--background-color);
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.05);
  z-index: 1000; /* Ensure the footer is above other content */
}

.face-gallery-button {
  background: var(--button-light-background-gradient);
  color: var(--button-text-color);
  font-weight: bold;
  padding: 20px;
  margin: 6px;
  border-radius: 15px;
  font-size: 36px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 5px 6px 7px rgba(0, 0, 0, 0.25);
}

.disabled-button {
  background: var(--button-disabled-background);
  color: var(--button-disabled-text-color);
  cursor: not-allowed;
  box-shadow: none;
}
