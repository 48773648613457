.admin-panel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: var(--background-color);
  font-family: "Secular One", sans-serif;
  padding: 20px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.section-header {
  font-size: 22px;
  font-weight: bold;
  color: var(--text-color);
  margin: 0;
}

.events-panel,
.create-event-section,
.resize-images-section {
  width: 100%;
  margin-bottom: 20px;
}

.refresh-button {
  background: none;
  border: none;
  font-size: 1.3rem;
  cursor: pointer;
  color: #18a5b8;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.heroku-status-container {
  width: 100%;
  margin-bottom: 20px;
}

.billing-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin-bottom: 15px;
  width: 100%;
}

.billing-row {
  color: var(--text-color2);
  font-size: 1.3em;
  font-weight: 500;
}

.billing-info {
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
}

.billing-text {
  color: var(--text-color2);
  font-size: 1.15em;
  margin: 0;
  font-weight: 500;
}

.heroku-status {
  background-color: rgba(218, 216, 163, 0.15);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  display: grid;
  grid-template-columns: minmax(10px, auto) minmax(10px, auto) minmax(
      10px,
      auto
    );
  /* grid-template-columns: minmax(120px, auto) minmax(80px, auto) minmax(150px, 1fr); */
  align-items: center;
  gap: 10px 20px;
  width: 100%;
}

.pricing-value {
  font-size: 0.9em;
  color: #666;
  text-align: left;
  white-space: nowrap;
}

.monthly-price {
  display: block;
  font-size: 0.85em;
  color: #888;
}

.specs {
  display: block;
  font-size: 0.8em;
  color: #777;
  margin-top: 3px;
}

.monthly-price,
.specs {
  margin-left: 5px;
}

.heroku-status select,
.heroku-status .redis-stat {
  grid-column: 1 / -1;
  width: 100%;
}

.heroku-status .dyno-label {
  text-align: left;
  font-weight: bold;
  font-size: 1em;
  white-space: nowrap;
}

.heroku-status .dyno-value {
  text-align: center;
  font-size: 1em;
  white-space: nowrap;
}
.heroku-status .redis-stat {
  text-align: center;
  font-size: 1em;
}

.redis-available {
  color: rgb(2, 162, 2);
  background-color: rgba(224, 247, 224, 0.95);
  padding: 5px;
  border-radius: 4px;
}

.redis-processing {
  color: rgb(132, 7, 248);
  background-color: rgba(222, 203, 240, 0.941);
  padding: 5px;
  border-radius: 4px;
}

.events-panel ul {
  list-style: none;
  padding: 0;
}

.events-panel li {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #d1d5db;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.events-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2px;
}

.header-left {
  text-align: left;
  margin-left: 2%;
}

.header-right {
  text-align: right;
  margin-right: 4%;
}

ul.events-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul.events-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

ul.events-list li.selected {
  background-color: #e0e0e0;
}

.event-details {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.event-name {
  flex: 2;
  text-align: left;
}

.event-date {
  flex: 1;
  text-align: center;
}

.status-indicator,
.work-indicator {
  margin-left: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.scrollable-panel {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
}

.status-indicator.green {
  background-color: green;
}

.status-indicator.red {
  background-color: red;
}

.work-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 8px;
  background-color: rgb(252, 236, 251);
}

.work-indicator.upcoming {
  background-color: rgb(224, 212, 36);
}

.work-indicator.ongoing {
  background-color: rgb(6, 6, 211);
}

.work-indicator.completed {
  background-color: rgb(24, 23, 23);
}

.create-event-panel,
.resize-images-panel {
  width: 100%;
  max-width: 400px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #d1d5db;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.create-event-panel form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.create-event-panel .form-row {
  display: flex;
  gap: 10px;
  width: 100%;
}

.create-event-panel .form-row .input-centered,
.create-event-panel .form-row .event-type-select {
  flex: 1;
}

.create-event-panel input {
  padding: 10px;
  border: 1px solid #d1d5db;
  border-radius: 5px;
}

.event-type-select {
  padding: 10px;
  border: 1px solid #d1d5db;
  border-radius: 5px;
  text-align: center;
}

.create-event-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.toggle-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  flex-shrink: 0;
}

.toggle-switch label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
}

.toggle-switch .slider {
  position: relative;
  width: 50px;
  height: 25px;
  background-color: #ccc;
  border-radius: 25px;
  transition: background-color 0.3s;
  margin-left: 5px;
}

.toggle-switch .slider::before {
  content: "";
  position: absolute;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: white;
  transition: transform 0.3s;
  left: 2px;
  top: 2px;
}

.toggle-switch input[type="checkbox"] {
  display: none;
}

.toggle-switch input[type="checkbox"]:checked + .slider {
  background-color: #4caf50;
}

.toggle-switch input[type="checkbox"]:checked + .slider::before {
  transform: translateX(24px);
}

.date-picker-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #d1d5db;
  border-radius: 5px;
  text-align: center;
  margin-bottom: 10px;
}

.date-picker-row {
  display: flex;
  gap: 10px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container input::placeholder {
  color: #a9a9a9;
  text-align: center;
}

.react-datepicker__input-container input:disabled {
  background-color: #e9ecef;
}

.input-centered {
  text-align: center;
}

.create-event-button {
  background: var(--button-light-background-gradient);
  color: var(--button-text-color);
  font-weight: bold;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.25);
}

.create-event-button:hover {
  transform: translateY(-2px);
}

.create-event-button:hover,
.resize-button:hover {
  background-color: initial;
}

.resize-input-button-container {
  display: flex;
  align-items: center;
}

.resize-input {
  padding: 10px;
  border: 1px solid #d1d5db;
  border-radius: 5px;
  flex: 1;
  margin-right: 10px;
}

.resize-button {
  background: var(--button-light-background-gradient);
  color: var(--button-text-color);
  font-weight: bold;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.25);
}

.resize-button:disabled {
  cursor: not-allowed;
  box-shadow: none;
  background-color: var(--button-disabled-background);
  color: var(--button-disabled-text-color);
}

.redis-controls {
  grid-column: 1 / -1;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.redis-controls select {
  flex: 1;
}

.redis-controls .redis-stat {
  white-space: nowrap;
  width: auto;
}
