@import url("https://fonts.googleapis.com/css2?family=Secular+One&display=swap");

:root {
  --primary-color: #1352d0;
  --secondary-color: #2f66ae;
  --tertiary-color: #2f73ae;
  --quaternary-color: #2f9bae;
  --background-color: #f5f9fa55;
  --text-color: #421313;
  --text-color2: #7b0202;
  --button-text-color: #ffffff;
  --button-background-gradient: linear-gradient(
    135deg,
    #1352d0,
    #2f66ae,
    #2f73ae,
    #2f9bae
  );
  --button-light-background-gradient: linear-gradient(
    135deg,
    #78bce0de,
    #94d0f0c4
  );
  --button-disabled-background: #919293c6;
  --button-disabled-text-color: #ffffff;
}

/* Font */
body {
  font-family: "Secular One", sans-serif;
}

/* Utility Classes */
.center-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  font-size: 24px;
  flex: 1;
}

button:hover {
  transform: translateY(-2px);
}

button:disabled {
  cursor: not-allowed;
  box-shadow: none;
  background-color: var(--button-disabled-background);
  color: var(--button-disabled-text-color);
}

.primary-button {
  background: var(--button-background-gradient);
  color: var(--button-text-color);
}

.primary-button:hover {
  background: var(--button-hover-background-gradient);
}

.primary-button:active {
  transform: translateY(2px);
}

.secondary-button {
  background: var(--button-light-background-gradient);
  color: var(--button-text-color);
}

.secondary-button:hover {
  background: var(--button-hover-background-gradient);
}

.secondary-button:active {
  transform: translateY(2px);
}

/* Title Styles */
.title {
  font-size: 48px;
  font-weight: bold;
  color: var(--text-color);
  background: var(--button-background-gradient);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
  margin-top: 20px;
  text-align: center;
}

.subtitle {
  font-size: 24px;
  color: #424242;
  margin-bottom: 20px;
}

/* RTL and LTR Classes */
.rtl {
  direction: rtl;
  /* text-align: right; */
}

.ltr {
  direction: ltr;
  /* text-align: left; */
}
