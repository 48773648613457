.whatsapp-button {
  position: fixed;
  bottom: 17%;
  right: 3%;
  background-color: #25d366;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  opacity: 0.7;
  transition: opacity 0.3s;
  color: white;
  z-index: 1000; /* Ensure the button is on top of other elements */
}

.whatsapp-button:hover {
  opacity: 1;
}
