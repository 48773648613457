@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./global.css";

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--background-color);
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--light-bg-color);
  font-family: "Secular One", sans-serif;
  /* overflow: hidden; */
  touch-action: manipulation;
}
